
.box {
    padding: 20px;
    border-radius: 20px;
    background-color: $u_white;
    border-top: 1px solid rgba(0, 0, 0, .1); /* Optional: light top border */
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, .16);
    position: relative;
  }
  
  .box-title {
    font-size: 1.3rem;
    font-weight: bold;
  }
  
  .box-title-big {
    font-size: 1.4rem
  }
  
  .box-network {
    margin: 20px;
    border-radius: 20px;
    background-color: $u_white;
    border-top: 1px solid rgba(0, 0, 0, .1); /* Optional: light top border */
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, .16);
  
  }