.menu-header {
  display: flex;
    background: "#2185D1";
  }
  
  .side-bar {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    height: 100%;
    flex-grow: 1;
    margin-bottom: auto;
  }
  
  .sidebarFooter {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding: 20px;
      color: $u_blue;
      background: transparent;
  }
  
 
  