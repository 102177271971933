.login-error {
    color: $u_red;
    text-align: center;
  }
  
  .auth-form {
    padding: 50px 50px;
  }
  
  .auth-form .btn {
    height: 50px;
    font-weight: 700;
  }
  
  .auth-form .page-back {
    display: inline-block;
    margin-bottom: 15px;
  }
  
  .login {
    background: $u_blue_light;
    display: flex;
    min-height: 100vh;
  }
  
  .form-check-label {
    margin-left: 0.3125rem;
  }
  
  
  .form-side {
    color: $u_gray;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    height: 100vh
  }
  
  @media only screen and (max-width: 87.5rem) {
    .login .login-aside {
      max-width: 22.5rem;
    }
  }
  
  @media only screen and (max-width: 61.9375rem) {
    .image-side {
      background: rgb(255, 255, 255);
      height: auto
    }
  
    .login .login-aside:after {
      content: none;
    }
  }
  
  @media only screen and (max-width: 35.9375rem) {
    .image-side {
      background: rgb(255, 255, 255);
      height: auto
    }
    .auth-form {
      padding: 30px;
    }
    .form-side {
      max-height: 400px;
      height: auto
    }
  
    .login-content.style-1 .auth-form {
      padding: 30px 0px;
    }
  }
  
  .login-content {
    border-radius: 10px;
  }
  
  .login-content.style-1 {
    background: #fff;
    max-width: 580px;
    width: 100%;
  }
  
  .login-content.style-1 .form-control {
    border: 0;
    border-radius: 5px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);
  }

  .login-header{
    background: #ffffff;
    color: $u_blue_light;
    max-width: 580px;
    width: 100%;
  }