input[type=text],
input[type=password],
input[type=email],
select {
  border: solid 2px $u_blue;
  border-radius: 30px;
  padding: 5px 10px;
  width: 100%;
  background: transparent;
  display: block;
  color: $u_blue_light;
  font-size: .95rem;
}


.select {
  border: solid 2px $u_blue;
  border-radius: 30px;
  padding: 5px 10px;
  width: 100%;
  background: transparent;
  display: block;
  color: $u_blue_light;
  font-size: .95rem;
}


