@import "~bootstrap/scss/bootstrap";
@import "var";
@import "menu";
@import "login";
@import "form";
@import "dashboard";
@import "box";
@import "buttons";


body {
    width: 100vw;
    overflow-x: hidden;
    background-color: $u_white;
    //padding-left: $side_width;
}

.card-header {
  background-color: $u_gray_light;
}

.container-fluid {
    padding-right: 24px;
    padding-left: 24px;
    margin-right: auto;
    margin-left: auto;
}


.page-title-bar {
    background: $u_gray_light;
    border-top: 2px solid $u_blue;
    border-bottom: 2px solid $u_blue;
}

.page-title-bar-text {
    font-size: 1.4rem;
    font-weight: bold;
    padding-top: 12px;
    padding-bottom: 12px;
}


.page-footer {
    background: #ffffff;
  }
  
  .page-footer-text {
    font-size: 1.5rem;
    font-weight: bold;
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .pn-shadow {
    box-shadow: 0 -1px 20px 0 rgba(0, 0, 0, .16);
  }