$u_blue: #2DABE3;

$u_blue_light: #2185D1;
$u_blue_medium: #4C7A8E;
$u_blue_dark: #4021D1;

$u_gray: #9D9D9C;
$u_gray_light: #EEF0F6;
$u_gray_dark: #706F6F;

$u_green: #81E32D;
$u_green_dark:#2DE3B7;

$u_yellow_light :#F2FF93;
$u_yellow_dark :#E3DB2D;

$u_purple_light:#D32DE3;
$u_purple_dark: #7A21D1;

$u_logo_gray: #706F6F;
$u_logo_yellow: #CFD222;

$u_black: #222222;
$u_red: #D12621;
$u_orange: #D15921;
$u_white: #FFF;

$side_width: 70px
